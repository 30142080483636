<template>
  <section class="section">
    <div id="hero-top" class="container is-fluid">
      <h1 class="title">Something amazing is coming soon</h1>

      <!-- <h1 class="title">The best Craigslist tool available</h1> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "Index",
  components: {
  },
  mixins: [],
  data() {
    return {
      modalComp: null,
      plans: [],
      selectedPlanId: null,
      leftPlan: {},
      centerPlan: {},
      rightPlan: {},
      publicPath: process.env.BASE_URL,
    }
  },
  beforeCreate() {},
  created() {
  },
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {

  },
  filters: {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
  margin: 0;
}

#hero-top {
  // @include angled-edge("inside bottom", "upper left", $white, 150);
  background-color: $primary;
  // background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
  background-image: linear-gradient(to top, #17a4ea 0%, #335eea 100%);

  margin: 0;
  min-height: 80vh;
  transition: 0.5s all ease;
  padding-bottom: 20px;
  h1,
  h2 {
    color: $white;
    padding-left: 30px;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
  }

  .title {
    font-size: 2.8em;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  h2 {
    font-size: 1.4em;
  }

  #preview {
    width: 99%;
    margin: 10px;
    background-color: $white;
    padding: 16px;
    border-radius: 6px;
  }
}

#benefits-blurb {
  margin: 0;
  min-height: 30vh;
  background-color: $white-ter;
}

#features {
  margin: 0;
  padding: 40px;

  min-height: 30vh;
  background-color: $white;

  #feature-description-tile {
    display: flex;
    flex-direction: column;
    line-height: 3;
    padding-right: 6px;
    h1 {
      font-size: 2.4em;
      font-weight: 500;
    }
  }

  #example-email {
    border: 1px solid $grey;
    border-radius: 6px;
    padding-left: 30px;
  }
}

.container {
  margin: 0;
  min-height: 20vh;
}

#live-stats {
  min-height: 10vh;
  margin: 0;
  background-color: $white-bis;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10vw;
  padding-right: 10vw;

  nav {
    padding: 40px;
    border: none;
  }
}

#pricing-table {
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;

  #pricing-table-title {
    font-weight: 700;
    font-size: 2.2em;
  }

  #pricing-table-header {
    text-align: center;
    padding: 50px;
    margin-top: 20px;

    p {
      font-style: italic;
    }
  }

  .pricing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
  }

  .pricing__item {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    text-align: center;
    -webkit-flex: 0 1 330px;
    flex: 0 1 330px;
  }

  .pricing__feature-list {
    text-align: left;
  }

  .pricing__action {
    color: inherit;
    border: none;
    background: none;
  }

  .pricing__action:focus {
    outline: none;
  }

  /* palden */
  .pricing--palden .pricing__item {
    font-family: "Nunito", sans-serif;
    cursor: default;
    color: $grey-dark;
    background: #fff;
    box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
    border-radius: 20px 20px 10px 10px;
    margin: 1em;
  }

  @media screen and (min-width: 66.25em) {
    .pricing--palden .pricing__item {
      margin: 1em -0.5em;
    }
    .pricing--palden .pricing__item--featured {
      margin: 0;
      z-index: 10;
      box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
    }
  }

  .pricing--palden .pricing__deco {
    border-radius: 10px 10px 0 0;
    // background-image: linear-gradient(180deg, $primary 0%, #00f2fe 100%);
    background-image: linear-gradient(
      180deg,
      #335eea 0%,
      mix(#00f2fe, #17a4ea, 60%) 100%
    );

    padding: 4em 0 9em;
    position: relative;
  }

  .pricing--palden .pricing__deco-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 17a4ea0px;
  }

  .pricing--palden .pricing__item--featured .pricing__deco {
    padding: 5em 0 8.885em 0;
  }

  .pricing--palden .pricing__title {
    font-size: 1.2em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $white;

    font-weight: 700;
  }

  .pricing--palden .deco-layer {
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
  }

  .pricing--palden .pricing__item:hover .deco-layer--1 {
    -webkit-transform: translate3d(15px, 0, 0);
    transform: translate3d(15px, 0, 0);
  }

  .pricing--palden .pricing__item:hover .deco-layer--2 {
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0);
  }

  .pricing--palden .icon {
    font-size: 2.5em;
  }

  .pricing--palden .pricing__price {
    font-size: 5em;
    font-weight: bold;
    padding: 0;
    color: #fff;
    margin: 0 0 0.25em 0;
    line-height: 0.75;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);

    .price-cents {
      vertical-align: top;
    }
  }

  .pricing--palden .pricing__currency {
    font-size: 0.15em;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.4);
  }

  .pricing--palden .pricing__period {
    font-size: 0.15em;
    padding-top: 15px;
    color: white;
    font-style: italic;
    text-shadow: none;
  }

  .pricing--palden .pricing__sentence {
    font-weight: bold;
    margin: 0 0 1em 0;
    padding: 0 0 0.5em;
  }

  .pricing--palden .pricing__feature-list {
    margin: 0;
    padding: 0.25em 1.3em 1.5em;
    list-style: none;
    text-align: center;
  }

  .pricing--palden .pricing__feature {
    padding: 1em 0;
  }

  .pricing--palden .pricing__action {
    font-weight: bold;
    margin: auto 3em 2em 3em;
    padding: 1em 2em;
    color: #fff;
    border-radius: 30px;
    background: lighten($primary, 10%);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  .pricing--palden .pricing__action:hover,
  .pricing--palden .pricing__action:focus {
    background-color: $primary;
  }

  #pricing-question-button {
    margin: 60px;
    border: 2px solid $primary;
    border-radius: 10px;
  }
}

@media (max-width: 850px) {
  #hero-top {
    // @include angled-edge("inside bottom", "upper left", $white, 80);
    height: 50vh;
  }

  nav {
    display: block;
    height: auto;
    div {
      margin: 10px;
    }
  }

  #preview {
    display: none;
  }
}
</style>
